<template>
  <div>
    <v-carousel
      v-if="$vuetify.breakpoint.xsOnly"
      show-arrows-on-hover
      :cycle="true"
      :interval="9000"
      :height="260"
    >
      <v-carousel-item
        v-for="(img, name) in images"
        :key="name"
        :src="img"
      >
      </v-carousel-item>
    </v-carousel>

    <v-container>
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <v-col sm="6" lg="3">
          <v-row no-gutters>
            <v-col>
              <v-img height="150" :src="images.img01" class="mb-6"></v-img>
            </v-col>
            <v-col>
              <v-img height="150" :src="images.img02"></v-img>
            </v-col>
          </v-row>
        </v-col>

        <v-col sm="6" lg="6">
          <v-img height="324" :src="images.img03"></v-img>
        </v-col>

        <v-col sm="12" lg="3">
          <v-row no-gutters>
            <v-col sm="6" lg="12">
              <v-img
                height="150"
                :src="images.img04"
                :class="$vuetify.breakpoint.lgAndUp ? 'mb-6' : 'mr-3'"
              ></v-img>
            </v-col>
            <v-col sm="6" lg="12">
              <v-img
                height="150"
                :src="images.img05"
                :class="$vuetify.breakpoint.lgAndUp ? '' : 'ml-3'"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <h1 class="mt-8 mb-6 text-h3 font-weight-light text-center">
        {{ $vuetify.lang.t(title) }}
      </h1>

      <div v-html="$vuetify.lang.t(text)"></div>

    </v-container>
  </div>
</template>

<script>
import { sakkara } from '@/scripts/images';

export default {
  name: 'KomElShouqafasCatacombs',
  data: () => ({
    title: '$vuetify.attractions.catacombs.title',
    text: '$vuetify.attractions.catacombs.text',
    images: {
      img01: sakkara.sakkara01,
      img02: sakkara.sakkara02,
      img03: sakkara.sakkara03,
      img04: sakkara.sakkara04,
      img05: sakkara.sakkara05,
    },
  }),
};
</script>

<style lang="scss"></style>
